import styled from '@emotion/styled';
import { dehydrate, DehydratedState, Hydrate, QueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { ReactElement } from 'react';
import AppUpdateAlarm from 'components/common/AppUpdateAlarm';
import Header from 'components/common/Header';
import PageHeadMeta from 'components/common/PageHeadMeta';
import ScrollRestoreForSSR from 'components/common/ScrollRestoreForSsr';
import useHomeBottomSheetNotify from 'components/home/hooks/useHomeBottomSheetNotify';
import { fetchHomeTabList, GET_HOME_TAB_LIST_QUERY } from 'components/home/hooks/useHomeTabList';
import {
  fetchRecentRelatedProductList,
  RECENT_RELATED_PRODUCT_LIST_QUERY_KEY,
} from 'components/home/hooks/useRecentRelatedProductList';
import {
  fetchTimeSaleProductList,
  TIME_SALE_PRODUCT_LIST_QUERY_KEY,
} from 'components/home/hooks/useTimeSaleProductList';
import Layout from 'components/home/Layout';
import TabBar from 'components/home/TabBar';
import Tabs, { HomeTabType } from 'components/home/Tabs';
import { SHARED_INVITE_CODE_STORAGE_KEY } from 'components/invite-friends/store';
import { fetchUserInfo, USER_INFO_QUERY_KEY } from 'components/my-page/hooks/useUserInfo';
import { A_B_TEST_QUERY_KEY, fetchUserFeatureList } from 'hooks';
import { useAppVersion } from 'hooks/app/useAppVersion';
import {
  PRODUCT_LIST_TYPE,
  fetchActivatedDisplayCategory,
  ACTIVE_BANNER_LIST_QUERY_KEY,
  BANNER_PAGE_NAME,
  fetchActiveBannerList,
  ACTIVATED_DISPLAY_CATEGORY_QUERY_KEY,
} from 'hooks/queries';
import { useIsomorphicLayoutEffect } from 'hooks/useIsomorphicLayoutEffect';
import { WEB_MAX_WIDTH, white } from 'styles';
import { Toast } from 'util/Toast';

export enum HOME_STORAGE_KEY {
  TOAST_MESSAGE = 'HOME_TOAST_MESSAGE',
}

interface Props {
  dehydratedState: DehydratedState;
}

/**
 * 메인 페이지
 * @component
 */
const HomePage = () => {
  const {
    query: { tab, sheet_type, invite_code },
  } = useRouter();
  const { is_native_version } = useAppVersion();
  useHomeBottomSheetNotify(String(sheet_type));

  useIsomorphicLayoutEffect(() => {
    const toast_message = localStorage.getItem(HOME_STORAGE_KEY.TOAST_MESSAGE);
    if (toast_message) {
      Toast.show(toast_message);
      localStorage.removeItem(HOME_STORAGE_KEY.TOAST_MESSAGE);
    }
  }, []);

  // 친구초대 링크로 들어온 경우 코드 저장 하여 코드작성에서 자동 노출
  useIsomorphicLayoutEffect(() => {
    if (invite_code) {
      window.localStorage.setItem(SHARED_INVITE_CODE_STORAGE_KEY, String(invite_code));
    }
  }, [invite_code]);

  const selected_tab_id = getSelectedTabType(typeof tab === 'string' ? tab : undefined);
  // @ts-ignore
  const SelectedHomeTab = Tabs[selected_tab_id];

  return (
    <Main>
      {!is_native_version && (
        <>
          <Header.Home />
          <TabBar selected_tab_id={selected_tab_id} />
        </>
      )}
      <SelectedHomeTab />
      <AppUpdateAlarm />
      <ScrollRestoreForSSR getSelectedTabType={getSelectedTabType} />
    </Main>
  );
};
export default HomePage;

function getSelectedTabType(tab?: string) {
  if (!tab) {
    return HomeTabType.HOT;
  }

  return Object.values(HomeTabType).includes(tab as HomeTabType) ? tab : HomeTabType.HOT;
}

HomePage.getLayout = (page: ReactElement<Props>) => {
  return (
    <Layout>
      <Hydrate state={page.props.dehydratedState}>
        <PageHeadMeta title='홈' og={{ path: '/' }} />
        {page}
      </Hydrate>
    </Layout>
  );
};

// @ts-ignore
HomePage.getInitialProps = async ({ query, req }) => {
  const queryClient = new QueryClient();
  const is_sever_render = !!req;

  await Promise.all([
    queryClient.prefetchQuery(A_B_TEST_QUERY_KEY, () => fetchUserFeatureList(req)),
    queryClient.prefetchQuery(GET_HOME_TAB_LIST_QUERY, () => fetchHomeTabList(req)),
  ]);

  //BEST탭 SSR fetching
  if (is_sever_render && query.tab === 'best') {
    await queryClient.prefetchQuery([ACTIVATED_DISPLAY_CATEGORY_QUERY_KEY, PRODUCT_LIST_TYPE.BEST], () =>
      fetchActivatedDisplayCategory(PRODUCT_LIST_TYPE.BEST, req),
    );
  }
  //SALE탭 SSR fetching
  if (is_sever_render && query.tab === 'sale') {
    await Promise.all([
      queryClient.prefetchQuery(TIME_SALE_PRODUCT_LIST_QUERY_KEY, () => fetchTimeSaleProductList(req)),
      queryClient.prefetchQuery([ACTIVATED_DISPLAY_CATEGORY_QUERY_KEY, PRODUCT_LIST_TYPE.DISCOUNTED_NEW], () =>
        fetchActivatedDisplayCategory(PRODUCT_LIST_TYPE.DISCOUNTED_NEW, req),
      ),
      queryClient.prefetchQuery([ACTIVE_BANNER_LIST_QUERY_KEY, BANNER_PAGE_NAME.SALE], () =>
        fetchActiveBannerList(BANNER_PAGE_NAME.SALE, req),
      ),
    ]);
  }
  //HOT탭 SSR fetching
  if (is_sever_render && query.tab !== 'sale' && query.tab !== 'best') {
    await Promise.all([
      queryClient.prefetchQuery(TIME_SALE_PRODUCT_LIST_QUERY_KEY, () => fetchTimeSaleProductList(req)),
      queryClient.prefetchQuery(USER_INFO_QUERY_KEY, () => fetchUserInfo(req)),
      queryClient.prefetchQuery([ACTIVE_BANNER_LIST_QUERY_KEY, BANNER_PAGE_NAME.TREND], () =>
        fetchActiveBannerList(BANNER_PAGE_NAME.TREND, req),
      ),
      queryClient.prefetchQuery([ACTIVATED_DISPLAY_CATEGORY_QUERY_KEY], () => fetchActivatedDisplayCategory(null, req)),
      queryClient.prefetchQuery([RECENT_RELATED_PRODUCT_LIST_QUERY_KEY], () => fetchRecentRelatedProductList(req)),
    ]);
  }

  return { dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))) };
};

const Main = styled.main`
  max-width: ${WEB_MAX_WIDTH}px;
  margin: 0 auto;
  background: ${white};
`;
