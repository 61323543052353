import styled from '@emotion/styled';
import { FC, memo } from 'react';
import { useHomeTabList } from '../hooks/useHomeTabList';
import Tabs from 'components/base/Tabs';
import { HEADER_HEIGHT } from 'styles';

interface Props {
  selected_tab_id: string;
}

/**
 * Home 탭 상단 탭바
 * @component
 */
const TabBar: FC<Props> = ({ selected_tab_id }) => {
  const { home_sub_tab_list } = useHomeTabList();

  return (
    <SC.Container>
      <Tabs use_transition_loading tab_list={home_sub_tab_list} selected_tab_id={selected_tab_id} />
    </SC.Container>
  );
};

export default memo(TabBar);

const SC = {
  Container: styled.div`
    position: sticky;
    left: 0;
    right: 0;
    z-index: 310;
    top: ${HEADER_HEIGHT}px;
  `,
};
