import { useAtom } from 'jotai';
import { openBottomSheetListAtom } from '../store/openBottomSheetList';
import { useAdNotiAllow } from './useAdNotiAllow';
import { getUserInfo } from 'api2';
import { useLoginDialog } from 'components/auth/Login/LoginDialog/useLoginDialog';
import { CONFIRM_ID, useBottomDialog } from 'context/ButtomDialogContext';
import { useFbkRouter } from 'hooks';
import { useFinishSplash } from 'hooks/app/useFinishSplash';
import { useIsomorphicLayoutEffect } from 'hooks/useIsomorphicLayoutEffect';
import { openAppSettings } from 'util/app_utils';
import {
  BENEFIT_DIALOG_TITLE,
  getStorage,
  home_dialog_info,
  HOME_DIALOG_TYPE,
  setStorage,
} from 'util/home_dialog_info_util';
import { useIsApp } from 'util/is_app';

// 홈 진입시 노출할 바텀싯 타입
export enum HOME_BOTTOM_SHEET_TYPE {
  // 가입 축하 포인트
  REGISTER_WELCOME_POINT = 'register_welcome_point',
  // 가입 축하 포인트 with 쿠폰
  REGISTER_WELCOME_POINT_WITH_COUPON = 'register_welcome_point_with_coupon',
  // 첫 가입후 혜택 및 소식 알람 설정
  BENEFIT_AND_NOTIFY = 'benefit_and_notify',
  // 비회원 로그인 유도 설정
  LOGIN = 'login',
}

const only_once_open_sheet_list = [
  HOME_BOTTOM_SHEET_TYPE.REGISTER_WELCOME_POINT_WITH_COUPON,
  HOME_BOTTOM_SHEET_TYPE.REGISTER_WELCOME_POINT,
  HOME_BOTTOM_SHEET_TYPE.BENEFIT_AND_NOTIFY,
];

/**
 * 홈화면 진입시 노출할 bottomSheet 정의 hook
 * @hook
 */
const useHomeBottomSheetNotify = (sheet_type: string) => {
  const [open_bottom_sheet_list, setOpenBottomSheetList] = useAtom(openBottomSheetListAtom);
  const router = useFbkRouter();
  const { open: openBottomDialog } = useBottomDialog();
  const { openLoginDialog } = useLoginDialog();
  const { onAdNotify, updateUserAdNotiForAgree } = useAdNotiAllow();
  const { is_finish_splash } = useFinishSplash();
  const is_app = useIsApp();

  useIsomorphicLayoutEffect(() => {
    if (!is_finish_splash) {
      return;
    }

    // 한번만 노출되어야 하는 bottom_sheet 처리
    if (open_bottom_sheet_list.some((show_bottom_sheet) => show_bottom_sheet === sheet_type)) {
      return;
    }

    if (only_once_open_sheet_list.some((sheet) => sheet_type === sheet)) {
      setOpenBottomSheetList([...only_once_open_sheet_list, sheet_type]);
    }

    switch (sheet_type) {
      case HOME_BOTTOM_SHEET_TYPE.REGISTER_WELCOME_POINT:
      case HOME_BOTTOM_SHEET_TYPE.REGISTER_WELCOME_POINT_WITH_COUPON: {
        // MEMO: 알림허용 dialog -> 포인트 확인용 dialog 2depth dialog 구조
        const openRegisterWelcomDialog = async (is_agree_user_ad_notify: boolean) => {
          const point_dialog_button_state = await openBottomDialog({ ...home_dialog_info[sheet_type] });

          const is_move_coupon_page = point_dialog_button_state === CONFIRM_ID.OK;

          // 알림 신청 한 경우 알림 여부 처리 후 포인트 페이지 이동 여부 처리
          if (is_agree_user_ad_notify) {
            updateUserAdNotiForAgree({
              onSettled: () => {
                if (is_move_coupon_page) {
                  router.push('/coupons');
                }
              },
            });
            return;
          }

          if (is_move_coupon_page) {
            router.push('/coupons');
          }
        };
        const openInviteCodePointDialog = async () => {
          const benefit_dialog_button_status = await openBottomDialog({
            ...home_dialog_info[HOME_DIALOG_TYPE.BENEFIT],
            contents: {
              ...home_dialog_info[HOME_DIALOG_TYPE.BENEFIT].contents,
              title: BENEFIT_DIALOG_TITLE.SIGNUP,
            },
          });

          if (benefit_dialog_button_status !== CONFIRM_ID.OK) {
            setStorage.benefitRemind();
            openRegisterWelcomDialog(false);
            return;
          }

          const push_status = getStorage.appPushStatus();

          // 기기알림 허용한 경우 or web인경우, 기기알림 팝업 표시 않고 두번째 dialog 표시
          if (!push_status || !is_app) {
            openRegisterWelcomDialog(true);
            return;
          }

          const device_status = await openBottomDialog({ ...home_dialog_info[HOME_DIALOG_TYPE.APP_PUSH] });

          if (device_status === CONFIRM_ID.OK) {
            openAppSettings();
          }
          openRegisterWelcomDialog(true);
          setStorage.pushRemind();
        };

        openInviteCodePointDialog();
        return;
      }
      case HOME_BOTTOM_SHEET_TYPE.BENEFIT_AND_NOTIFY: {
        onAdNotify({ benefit_dialog_title: BENEFIT_DIALOG_TITLE.SIGNUP });
        return;
      }
      case HOME_BOTTOM_SHEET_TYPE.LOGIN: {
        const openUserLoginDialogSheet = async () => {
          const {
            data: { user_account },
          } = await getUserInfo();

          if (!user_account) {
            openLoginDialog();
          }
        };
        openUserLoginDialogSheet();
        return;
      }
    }
  }, [sheet_type, is_app, is_finish_splash]);
};

export default useHomeBottomSheetNotify;
