import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { HomeTabType } from '../Tabs';
import { getNativeAppTabList } from 'api2';
import { MAIN_TAB_ID } from 'util/app_utils';

export const GET_HOME_TAB_LIST_QUERY = ['getHomeTabList'];

const SUB_TAB_NAME: { [key: string]: string } = {
  home_ad: HomeTabType.HOT,
  home_promotion: HomeTabType.PROMOTION,
  home_best: HomeTabType.BEST,
  home_curation: HomeTabType.NEW,
  home_benefits: HomeTabType.SALE,
  home_kids: HomeTabType.KIDS,
};

export const fetchHomeTabList = async (req?: IncomingMessage) => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };
  try {
    const {
      data: { native_app_tab_list },
    } = await getNativeAppTabList(null, context_request);
    return native_app_tab_list;
  } catch (error) {
    return [];
  }
};

/**
 * 탭리스트 조회 hook
 * @hooks
 */
export const useHomeTabList = () => {
  const { data, isLoading: is_loading, ...rest } = useQuery(GET_HOME_TAB_LIST_QUERY, () => fetchHomeTabList());

  const home_sub_tab_list = useMemo(() => {
    const sub_tab_list = data?.find((tab) => tab.id === MAIN_TAB_ID.HOME)?.tab_list ?? [];
    return sub_tab_list.map((tab) => {
      return {
        id: SUB_TAB_NAME[tab.page_key ?? ''],
        label: tab.name,
        href: tab.landing_url ?? '',
        active_name_color: tab.name_color.active_name_color,
        inactive_name_color: tab.name_color.inactive_name_color,
      };
    });
  }, [data]);

  return {
    home_sub_tab_list,
    ...rest,
  };
};
