import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { useScrollRestoration } from 'context/ScrollRestorationContext';
import { useIsomorphicLayoutEffect } from 'hooks/useIsomorphicLayoutEffect';

interface Props {
  getSelectedTabType: (tab: string) => string;
}

/**
 *  스크롤 복원 처리용도
 *  https://reactjs.org/link/uselayouteffect-ssr
 * @component
 */
const ScrollRestoreForSSR: FC<Props> = ({ getSelectedTabType }) => {
  const [showChild, setShowChild] = useState(false);

  useEffect(() => {
    setShowChild(true);
  }, []);

  if (!showChild) {
    return null;
  }
  return <ScrollRestoreChild getSelectedTabType={getSelectedTabType} />;
};

export default ScrollRestoreForSSR;

const ScrollRestoreChild: FC<Props> = ({ getSelectedTabType }) => {
  useScrollRestore({ getSelectedTabType });
  return <></>;
};

function useScrollRestore({ getSelectedTabType }: { getSelectedTabType: (tab: string) => string }) {
  const { events } = useRouter();
  const { setScroll, getScroll } = useScrollRestoration();

  useIsomorphicLayoutEffect(() => {
    const getPageId = () => {
      const tab = new URLSearchParams(location.search).get('tab');
      return getSelectedTabType(String(tab));
    };

    const routeChangeCompleteHandler = () => {
      window.scrollTo(0, getScroll(getPageId()) || 0);
    };

    const beforeHistoryChangeHandler = () => {
      setScroll(getPageId(), window.scrollY);
    };

    events.on('routeChangeComplete', routeChangeCompleteHandler);
    events.on('beforeHistoryChange', beforeHistoryChangeHandler);
    return () => {
      events.off('routeChangeComplete', routeChangeCompleteHandler);
      events.off('beforeHistoryChange', beforeHistoryChangeHandler);
    };
  }, [setScroll, getScroll]);
}
