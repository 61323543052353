import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { CONFIRM_ID, useBottomDialog } from 'context/ButtomDialogContext';
import { useAppVersion } from 'hooks/app/useAppVersion';
import useBrowserEffect from 'hooks/useBrowserEffect';
import { global_scope } from 'store';
import { device_info_atom } from 'store/device';
import { moveAppUpdateMarketLink } from 'util/app_utils';

const STORAGE_KEY = 'APP_VERSION_UPDATE_NOTIFICATION';

const AppUpdateAlarm = () => {
  const { is_ios, is_app } = useAtomValue(device_info_atom, global_scope);
  const { is_native_version } = useAppVersion();
  const { open } = useBottomDialog();

  const is_show_dialog = useMemo(() => !is_native_version && is_app, [is_app, is_native_version]);

  useBrowserEffect(() => {
    async function callDialog() {
      const id = await open({
        contents: {
          title: '더 편안한 쇼핑을 위해 \n앱 업데이트를 해주세요.',
          text: '앱 업데이트를 하시겠어요?',
        },
        buttons: [
          {
            id: CONFIRM_ID.CANCEL,
            children: '나중에 하기',
            variant: 'tertiary_gray',
            size: 'large',
          },
          {
            id: CONFIRM_ID.OK,
            children: '업데이트 하기',
            size: 'large',
          },
        ],
      });

      if (id === CONFIRM_ID.OK) {
        const market_link = moveAppUpdateMarketLink(is_ios ? 'ios' : 'aos');
        window.location.href = market_link;
        return;
      }
    }

    const is_show_modal_date = localStorage.getItem(STORAGE_KEY);

    if (is_show_dialog && !is_show_modal_date) {
      callDialog();
      localStorage.setItem(STORAGE_KEY, dayjs().toString());
    }
  }, [is_show_dialog]);

  return null;
};

export default AppUpdateAlarm;
